<template>
  <div class="d-flex flex-column flex-shrink-0 vh-100 overflow-hidden bg-white">
    <user-view />
  </div>
</template>

<script>
import UserView from '@/components/user-view/UserView.vue'

export default {
  components: {
    UserView
  }
};
</script>